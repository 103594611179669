import RecipeMetrics from "@components/recipes/recipeMetrics";
import RecipeTags from "@components/recipes/recipeTags";
import { BlocksContent, Image, Link } from "@global";
import Layout from "@shared/layout";
import AllergenTags from "@shared/sub/allergensInner";
import { useStore } from "@state/store";
import {
  A,
  Container,
  Button,
  H1,
  H3,
  P,
  GiveMeSomeSpace,
} from "@util/standard";
import React, { useEffect } from "react";
import styled from "styled-components";
import Fraction from "fraction.js";
import {
  Maybe,
  SanityIngredientGroup,
  SanityMethodGroup,
} from "@graphql-types";
import { GlobalStyle } from "@styles/globalStyles";
import { navigate } from "gatsby-link";
import { colors } from "@util/constants";

const StyledMethodStep = styled.ol`
  padding-left: 20px;
  li {
    margin-bottom: 24px;
  }
`;

const StyledIngredientList = styled.ul`
  padding-left: 20px;
  width: 80%;
`;

const Print = () => {
  const { printableRecipe } = useStore();

  if (!printableRecipe || printableRecipe.recipe == null) {
    return (
      <Layout>
        <Container width="100%" backgroundColor="barkersDarkGray">
          <Container
            margin="150px auto"
            width="60%"
            backgroundColor="barkersDarkGray"
          >
            <H3 color="white">
              Opps.. looks like you landed here by mistaked. Please go back
            </H3>
          </Container>
        </Container>
      </Layout>
    );
  }
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (typeof window != "undefined") {
      }
    }, 1000);
  }, []);

  const handleBack = () => {
    navigate(-1);
  };

  const handlePrint = () => {
    window.print();
  };

  const recipe = printableRecipe.recipe;
  const ingredientGroups: Maybe<Maybe<SanityIngredientGroup>[]> | undefined =
    recipe?.ingredientsMethod?.ingredientsGroup;
  const methodGroups: Maybe<Maybe<SanityMethodGroup>[]> | undefined =
    recipe?.ingredientsMethod?.methods;

  return (
    <Container flexDirection="column" width="21cm" height="auto" margin="auto">
      <GlobalStyle />
      <Container width="fit-content" height="50px" margin="30px auto">
        <Button
          color="merlin"
          hoverColour="white"
          border={`1.5px solid ${colors.merlin}`}
          height="48px"
          backgroundColour="white"
          borderRadius="36px"
          onClick={handleBack}
        >
          Back
        </Button>
        <GiveMeSomeSpace />
        <Button
          color="merlin"
          hoverColour="white"
          border={`1.5px solid ${colors.merlin}`}
          height="48px"
          backgroundColour="white"
          borderRadius="36px"
          onClick={handlePrint}
        >
          Print
        </Button>
      </Container>
      <Container
        width="50%"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="flex-start"
        margin="auto"
      >
        {recipe.info?.featuredMedia?.image?.asset && (
          <Image
            imageData={recipe.info?.featuredMedia?.image?.asset.gatsbyImageData}
          />
        )}
      </Container>
      <Container flexDirection="column" width="100%" margin="10px 30px">
        {recipe.info && (
          <H1 fontFamily="Doyle" fontSize={24}>
            {recipe?.info.title}
          </H1>
        )}

        <AllergenTags allergens={recipe?.info?.allergens} />

        {/* <RecipeMetrics recipe={recipe} /> */}

        <BlocksContent blocks={recipe?.info?.recipeDescription?._rawBlocks} />

        {recipe.info && recipe?.info.authorName ? (
          <>
            {recipe?.info.authorLink ? (
              <P>
                By{" "}
                <a href={recipe.info.authorLink} target="_blank">
                  {recipe.info.authorName}
                </a>
              </P>
            ) : (
              <P>By {recipe.info.authorName}</P>
            )}
          </>
        ) : (
          <P>By Barker's</P>
        )}

        {recipe?.info?.tags && <RecipeTags recipe={recipe} />}

        <H3 fontFamily="Poppins" fontSize={23} margin="30px 0 0 0 ">
          Ingredients
        </H3>
        {ingredientGroups &&
          ingredientGroups.length > 0 &&
          ingredientGroups.map(ingredientGroup => {
            return (
              <Container
                key={ingredientGroup._key}
                display="flex"
                flexDirection="column"
              >
                {ingredientGroup?.group && <h4>{ingredientGroup.group}</h4>}
                <StyledIngredientList>
                  {ingredientGroup?.ingredients &&
                    ingredientGroup?.ingredients.length > 0 &&
                    ingredientGroup?.ingredients?.map((ingredient: any) => {
                      const measureFraction = new Fraction(ingredient.measure);

                      return (
                        <>
                          {ingredient?.ingredient?.clickableLink?.url &&
                          ingredient?.ingredient?.clickableLink?.linktext ? (
                            <li>
                              {ingredient.measure &&
                                measureFraction.toFraction(true)}{" "}
                              {ingredient?.displayValue}
                              {ingredient?.measureType}
                              {ingredient?.measureReference?.title}{" "}
                              <A
                                href={ingredient?.ingredient?.clickableLink.url}
                                bold
                                color="merlin"
                                target="blank"
                              >
                                {ingredient?.ingredient?.displayName}
                              </A>
                            </li>
                          ) : (
                            <li key={ingredient._key}>
                              {ingredient.measure &&
                                measureFraction.toFraction(true)}{" "}
                              {ingredient?.displayValue}
                              {ingredient?.measureType}
                              {ingredient?.measureReference?.title}{" "}
                              {ingredient?.ingredient?.displayName}
                            </li>
                          )}
                        </>
                      );
                    })}
                </StyledIngredientList>
              </Container>
            );
          })}

        <H3 fontFamily="Poppins" fontSize={23} margin="30px 0 0 0 ">
          Method
        </H3>
        {methodGroups &&
          methodGroups.length > 0 &&
          methodGroups.map(methodGroup => {
            return (
              <Container display="flex" flexDirection="column">
                {methodGroup?.methodGroup && (
                  <H3 fontFamily="Poppins" fontSize={23} margin="30px 0 0 0 ">
                    {methodGroup.methodGroup}
                  </H3>
                )}
                <StyledMethodStep>
                  {methodGroup?.steps &&
                    methodGroup.steps.length > 0 &&
                    methodGroup.steps.map(step => <li>{step}</li>)}
                </StyledMethodStep>
              </Container>
            );
          })}
      </Container>
    </Container>
  );
};

export default Print;
